import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'register', redirectTo: 'chatbot-register', pathMatch: 'full'},
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)},
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)},
  { path: 'not-found', loadChildren: () => import('./pages/not-found/not-found.module').then( m => m.NotFoundPageModule)},
  { path: 'terms', loadChildren: () => import('./pages/terms/terms.module').then( m => m.TermsPageModule)},
  { path: 'person', loadChildren: () => import('./pages/person/person.module').then( m => m.PersonPageModule)},
  { path: 'user', loadChildren: () => import('./pages/user/user.module').then( m => m.UserPageModule)},
  { path: 'companies', loadChildren: () => import('./pages/companies/companies.module').then( m => m.CompaniesPageModule)},
  { path: 'products', loadChildren: () => import('./pages/products/products.module').then( m => m.ProductsPageModule)},
  { path: 'promotions', loadChildren: () => import('./pages/home/promotions/promotions.module').then( m => m.PromotionsPageModule)},
  { path: 'tabloids', loadChildren: () => import('./pages/tabloids/tabloids.module').then( m => m.TabloidsPageModule)},
  { path: 'tabloid-show', loadChildren: () => import('./pages/tabloid-show/tabloid-show.module').then( m => m.TabloidShowPageModule)},
  { path: 'person-points', loadChildren: () => import('./pages/person-points/person-points.module').then( m => m.PersonPointsPageModule)},
  { path: 'sales-show', loadChildren: () => import('./pages/sales-show/sales-show.module').then( m => m.SalesShowPageModule)},
  { path: 'notifications', loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule)},
  { path: 'notification-control', loadChildren: () => import('./pages/notification-control/notification-control.module').then( m => m.NotificationControlPageModule)},
  { path: 'terms-and-privacity', loadChildren: () => import('./pages/terms-and-privacity/terms-and-privacity.module').then( m => m.TermsAndPrivacityPageModule)},
  { path: 'person-sale', loadChildren: () => import('./pages/person-sale/person-sale.module').then( m => m.PersonSalePageModule)},
  { path: 'sales-month-year-show', loadChildren: () => import('./pages/sales-month-year-show/sales-month-year-show.module').then( m => m.SalesMonthYearShowPageModule)},
  { path: 'person-financial', loadChildren: () => import('./pages/person-financial/person-financial.module').then( m => m.PersonFinancialPageModule)},
  { path: 'person-cashback', loadChildren: () => import('./pages/person-cashback/person-cashback.module').then( m => m.PersonCashbackPageModule)},
  { path: 'cashback-products', loadChildren: () => import('./pages/cashback-products/cashback-products.module').then( m => m.CashbackProductsPageModule)},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
