import { Injectable } from '@angular/core';
import { IPerson } from 'src/app/interfaces/register/person';
import { ITenant } from 'src/app/interfaces/tenant/tenant';
import { ICompany } from 'src/app/interfaces/company/company';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationStorageService {

  private currentUser: any;
  private tenantConfigurations: any;
  private currentPerson: any;
  private firebaseToken: string;
  

  constructor(private storageService: StorageService) { 
    this.loadData();
  }

  private loadData(){
    const user = this.storageService.get('user');
    const person = this.storageService.get('person');
    const configurations = this.storageService.get('configurations');
    
    if(user){
      this.currentUser = JSON.parse(user);
    }

    if(person){
      this.currentPerson = JSON.parse(person);
    }

    if(configurations){
      this.tenantConfigurations = JSON.parse(configurations);
    }
  }

  public getJWTtoken(): string {
    return this.currentUser ? this.currentUser.token : null;
  }

  public isRememberMe(): boolean {
    return this.currentUser ? this.currentUser.remember_me : false;
  }

  public getCompany(): ICompany {
    return this.currentPerson ? this.currentPerson.company : null;
  }
  
  public getPersonId(): number {
    return this.currentPerson ? this.currentPerson.id : null;
  }

  public getCompanyId(): number {
    return this.currentPerson ? this.currentPerson.company?.id : null;
  }

  public getTenant(): ITenant {
    if(this.tenantConfigurations){
      try {
        this.tenantConfigurations.colors_data = JSON.parse(this.tenantConfigurations.colors_data);
        this.tenantConfigurations.styles = JSON.parse(this.tenantConfigurations.styles);
      } catch (error) {}   
    }
    
    return this.tenantConfigurations ? this.tenantConfigurations : null;
  }

  public getPerson(): IPerson {
    return this.currentPerson ? this.currentPerson : null;
  }

  public getFirebaseToken(): string {
    return this.firebaseToken;
  }

  public setCurrentUser(currentUser: any): void {
    this.currentUser = currentUser;
    this.storageService.set('user', JSON.stringify(currentUser));
  }

  public setCompanyCurrentUser(company: ICompany): void {
    this.currentPerson.company = company;
    this.storageService.set('person', JSON.stringify(this.currentPerson));
  }

  public setCurrentPerson(currentPerson: any): void {
    this.currentPerson = currentPerson;
    this.storageService.set('person', JSON.stringify(currentPerson));
  }

  public setTenantConfigurations(tenantConfigurations: any): void {
    this.tenantConfigurations = tenantConfigurations;
    this.storageService.set('configurations', JSON.stringify(tenantConfigurations));
  }

  public setFirebaseToken(firebaseToken: string): void {
    this.firebaseToken = firebaseToken;
    this.storageService.set('firebaseToken', firebaseToken);
  }

  public unsetCurrentPerson(): void {
    this.currentPerson = null;
    this.currentUser = null;
    this.storageService.remove("user");  
    this.storageService.remove("person");
  }
}
