import { Component } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ApplicationStorageService } from './services/storage/application-storage.service';
import { register } from 'swiper/element/bundle';
import { ActivatedRoute } from '@angular/router';
import { IPerson } from './interfaces/register/person';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public styles: any;

  constructor(private navController: NavController, private route: ActivatedRoute, private applicationService: ApplicationStorageService) {}

  ngOnInit() {
    setTimeout(() => {
      this.initializeApp();
    }, 1000);
  }

  initializeApp() {
    let params = this.route.snapshot.queryParamMap.get('data');

    if (params) {    
      params = params.replace(/ /g, '+');
      
      try{
        let tenantConfig = JSON.parse(atob(params));

        if(!tenantConfig.tenant_home_buttons){
          tenantConfig.tenant_home_buttons = this.getButtons();
        }

        if(!tenantConfig.tenant_social_media){
          tenantConfig.tenant_social_media = this.getSocialMedias();
        }
        
        this.applicationService.setTenantConfigurations(tenantConfig);
        this.setTenantStyles();

        const person: IPerson = {
          id: 1,
          name: 'Pessoa 1', 
          date_nasc: new Date('2000-01-01'),
          sex: 'O', 
          telephone: '1234567890', 
          zip_code: '00000000',
          person_group_id: 1,
          neighborhood: 'Bairro 1', 
          number: 123, 
          street: 'Rua 1', 
          registered_by_app: true, 
          city_description: 'Cidade 1',
          city_uf: 'UF 1',
          city_ibge_code: 111111,
          company_id: 1,
          email: 'email@email.com',
          cpf_cnpj: '12345678901',
          company: {
            id: 1,
            cnpj: '12345678901234',
            name: 'Empresa 1',
            email: 'email@email.com',
            zip_code: '12345678',
            neighborhood: 'Bairro 1',
            number: 123,
            street: 'Rua 1',
            telephone: '1234567890',
            whatsapp: '1234567890',
            principal: true,
            city_description: 'Cidade 1',
            city_uf: 'UF 1'
          }
        }
        
        this.applicationService.setCurrentPerson(person);
        
        this.navController.navigateRoot('/home');
      } catch (error) {
        this.navController.navigateRoot('/not-found');
      }
    } else {
      this.navController.navigateRoot('/not-found');
    }
  }

  setTenantStyles(){
    let styles = this.applicationService.getTenant().styles;
    
    if (typeof styles === 'string') {
      styles = JSON.parse(styles);
    } 
    
    //Se adicionado mais estilos, adicionar aqui
    this.styles = {
      ...styles.buttons,
      ...styles.inputs,
      ...styles.headers,
      ...styles.icons,
      ...styles.cards,
      ...styles.texts
    }
  }

  getSocialMedias(){
    return [ 	  
      { 		
        social_media_id: "instagram", 		
        url: "https://www.instagram.com", 		
        icon: "logo-instagram", 		
        color:"#e4405f" 	  
      }, 	  
      { 		
        social_media_id: "whatsapp", 		
        url: "https://api.whatsapp.com", 		
        icon: "logo-whatsapp", 		
        color: "#25d366" 	  
      }, 	  
      { 		
        social_media_id: "facebook", 		
        url: "https://www.facebook.com", 		
        icon: "logo-facebook", 		
        color:"#3b5998"
      } 	
    ]
  }

  getButtons(){
    return [
      {
          button_id: 'products',
          index: 1,
          icon: '/assets/icon/search.svg',
          initial_description: 'Consulta de',
          final_description: 'Produtos',
          short_description: 'produto',
          route: 'products',
          active: true 
      },
      {
          button_id: 'promotions',
          index: 2,
          icon: '/assets/icon/discount.svg',
          initial_description: 'Consulta de',
          final_description: 'Promoções',
          short_description: 'promoção',
          route: 'promotions',
          active: true
      },
      {
          button_id: 'tabloids',
          index: 3,
          icon: '/assets/icon/tabloid.svg',
          initial_description: 'Consulta de',
          final_description: 'Tabloides',
          short_description: 'tabloide',
          route: 'tabloids?tabloid_type=tabloids',
          active: true
      },
      {
          button_id: 'cashback-products',
          index: 4,
          icon: '/assets/icon/cashback.svg',
          initial_description: 'Produtos com',
          final_description: 'Cashback',
          short_description: 'produto',
          route: 'cashback-products',
          active: true
      },
      {
          button_id: 'person-sale',
          index: 5,
          icon: '/assets/icon/cart.svg',
          initial_description: 'Minhas',
          final_description: 'Compras',
          short_description: 'compra',
          route: 'person-sale',
          active: true
      },
      {
          button_id: 'person-financial',
          index: 6,
          icon: '/assets/icon/card.svg',
          initial_description: 'Meu',
          final_description: 'Convênio',
          short_description: 'convênio',
          route: 'person-financial',
          active: true
      },
      {
          button_id: 'person-points',
          index: 7,
          icon: '/assets/icon/points.svg',
          initial_description: 'Meus',
          final_description: 'Pontos',
          short_description: 'ponto',
          route: 'person-points',
          active: true
      },
      {
          button_id: 'person-cashback',
          index: 8,
          icon: '/assets/icon/my-cashback.svg',
          initial_description: 'Meu',
          final_description: 'Cashback',
          short_description: 'cashback',
          route: 'person-cashback',
          active: true
      },
      {
          button_id: 'announcements',
          index: 9,
          icon: '/assets/icon/announcements.svg',
          initial_description: ' ',
          final_description: 'Informativos',
          short_description: 'informativo',
          route: 'tabloids?tabloid_type=announcements',
          active: true
      },
      {
          button_id: 'companies',
          index: 10,
          icon: 'business-outline',
          initial_description: 'Nossas',
          final_description: 'Lojas',
          short_description: 'loja',
          route: 'companies',
          active: true
      }
    ];
  }
}
