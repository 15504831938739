import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class HttpsInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.startsWith('https://') && window.location.protocol === 'https:') {
            const httpsReq = req.clone({ url: req.url.replace('https://', 'http://') });
            return next.handle(httpsReq);
        }
        return next.handle(req);
    }
}
