import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class StorageService {

    public set(key: string, value: any) {
        localStorage.setItem(key, value);
    }

    public get(key: string) {
        return localStorage.getItem(key);
    }

    public remove(key: string) {
        localStorage.removeItem(key);
    }
}